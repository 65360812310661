/* PopularBoards */
.boards_slide {
  position: relative;
}

.boards_slide_nav {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide_nav_btn {
  border-radius: 50%;
  color: var(--main-text-light);
  background-color: rgb(0 0 0 / 30%);
  transition: var(--transition-background);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}
.slide_nav_btn:hover {
  background-color: rgb(0 0 0 / 50%);
}
.boards_slide_nav.prev {
  left: -12px;
}
.boards_slide_nav.next {
  right: -12px;
}
.boards_slide_nav.hide {
  display: none;
}

.boards_slide_list, .slides_list {
  display: grid;
  grid-template-columns: repeat(6, minmax(112px, 1fr));
  grid-gap: 8px;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.slides_list {
  grid-template-columns: repeat(5, minmax(112px, 1fr));
}
@media (max-width: 746px) {
  .boards_slide_list::-webkit-scrollbar,
  .slides_list::-webkit-scrollbar,
  .sort_nav::-webkit-scrollbar,
  .breadcrumb_body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.boards_slide_item, .stats_item {
  border-radius: var(--border-radius);
  scroll-snap-align: center;
  scroll-snap-stop: always;
}
.boards_slide_item {
  width: 122px;
  height: 92px;
  background-color: var(--accent-color);
}
.stats_item {
  width: 100% !important;
  height: 112px !important;
  background-color: var(--secondary-bg-tint);
  transition: var(--transition-background);
}

.slide_item_text {
  display: flex;
  flex-direction: column;
  color: var(--main-text-light);
  width: 100%;
  height: 100%;
  padding: 10px;
}
.stats_item .slide_item_text {
  color: var(--main-text) !important;
}

.slide_title {
  font-size: 1.1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slide_content {
  font-size: .9rem;
  color: var(--secondary-text-light);
}
.stats_item .slide_content {
  color: var(--secondary-text);
}

/* Layout */
.container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1200px;
  min-width: 300px;
  margin: 0 auto;
}
.left_bar {
  width: 252px;
  position: sticky;
  top: 4.5rem;
  height: calc(100vh - 72px);
  overflow-y: auto;
  overflow-x: hidden;
}
.content {
  width: 63%;
}
.rigth_bar {
  width: 15%;
  top: 5.2rem;
  position: sticky;
  z-index: 7;
  margin: .7rem .6rem;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 780px) {
  .open_nav {
    display: flex;
  }
  .left_bar {
    background-color: var(--main-bg);
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    transform: translate(-260px, 0);
    transition: transform .2s;
    z-index: 9;
  }
  .left_bar.open {
    display: flex;
    transform: translateX(0);
  }
  .content, .main_nav {
    width: 100% !important;
  }
  .rigth_bar {
    margin: 0 !important;
    position: fixed !important;
    top: initial !important;
    bottom: 1rem;
    right: 1rem;
  }
}


.fab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-color);
  color: var(--main-text-light);
  height: 48px;
  border-radius: 50px;
  transition: var(--transition-background);
  box-shadow: 0 2px 8px -3px rgb(0 0 0 / 80%);
  cursor: pointer;
  user-select: none;
}
.fab:hover {
  background-color: var(--accent-color-tint)
}
.fab span {
  margin-right: .8rem;
}
.fab i {
  font-size: 20px;
}
.fab.secondary {
  background-color: var(--secondary-bg-tint);
  box-shadow: initial;
  margin-top: auto;
}

@media (max-width: 1050px) {
  .content {
    width: 58%;
  }
  .rigth_bar {
    top: 4.9rem;
    margin: .4rem .6rem;
    padding: 0;
    width: initial;
  }
  .fab {
    width: 56px;
    height: 56px;
  }
  .fab span {
    display: none;
  }
}


.cover {
  content: '';
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 60%);
  animation: growing .3s;
  z-index: 8;
}
.cover.open {
  display: block;
}
@keyframes growing {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgb(0 0 0 / 60%);
  }
}

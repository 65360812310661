/* Image lightbox */
@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, .8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
  z-index: 1000;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 35px;
  padding: 30px 25px;
  margin: auto;
  opacity: .7;
  cursor: pointer;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: .7;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, .2);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: var(--main-text-light);
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  margin: 0 !important;
  padding-left: 1rem;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  margin: 0 !important;
  padding-left: 0;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.ril__toolbarItem {
  display: flex;
  padding: 0;
  color: var(--main-text-light);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinTitle {
  margin-right: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .7;
  user-select: none;
}

.ril__builtinButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  opacity: .7;
  cursor: pointer;
  user-select: none;
}
.ril__builtinButton:hover {
  opacity: 1;
}
a.ril__builtinButton {
  color: var(--main-text-light);
  font-size: 20px;
}

.ril__builtinButtonDisabled {
  opacity: .5;
  cursor: default;
}
.ril__builtinButtonDisabled:hover {
  opacity: .5;
}

.ril__zoomOutButton, .ril__zoomInButton, .ril__closeButton {
  font-family: 'boxicons';
  background: none !important;
  color: var(--main-text-light);
  font-size: 22px;
}
.ril__closeButton {
  font-size: 30px;
}
.ril__zoomOutButton::before {
  content: '\ebed';
}
.ril__zoomInButton:before {
  content: '\ebec';
}
.ril__closeButton::before {
  content: '\ebe9';
}

.ril__navButtonPrev, .ril__navButtonNext {
  font-family: 'boxicons';
  background-color: rgb(0 0 0 / 20%);
  border-radius: var(--border-radius);
  font-size: 42px;
  color: var(--main-text-light);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ril__navButtonPrev {
  left: 0;
}
.ril__navButtonPrev::before {
  content: '\e9af';
}
.ril__navButtonNext {
  right: 0;
}
.ril__navButtonNext::before {
  content: '\e9b2';
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 50px;
  height: 50px;
  position: relative;
  border: 5px solid transparent;
  border-top: 5px solid var(--main-info-color);
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.ril__loadingCirclePoint {
  display: none;
}
.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__loadingContainer__icon {
  color: var(--main-text-light);
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-text-light);
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

/* Boards actions */
.edit_action_menu {
  display: flex;
  align-items: center;
}
.edit_action_menu .action {
  border-radius: var(--border-radius);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-background);
  cursor: pointer;
}
.edit_action_menu .action:not(:first-child) {
  margin-left: .3rem;
}
.edit_action_menu .action:hover {
  background-color: var(--secondary-bg-tint);
}
.edit_action_menu .action i {
  font-size: 18px;
}

.edit {
  color: var(--main-success-color) !important;
}
.delete {
  color: var(--main-danger-color) !important;
}
.cancel {
  color: var(--main-info-color) !important;
}
.cancel i {
  font-size: 22px !important;
}

.form_inner.edit_form {
  width: 100%;
  max-width: initial;
  margin: .25rem !important;
}
